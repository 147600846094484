import { $$, html, body } from "@utils/dom";
import { on, off } from "@utils/listener";

export const LOCAL_STORAGE_KEY = "bodyZoom";
class ZoomButtons {
  constructor(el, emitter) {
    this.el = el;
    this.emitter = emitter;

    this.buttons = [...$$(".zoom-buttons__btn", this.el)];

    this._zoom = localStorage.getItem(LOCAL_STORAGE_KEY) || 1;
    this._onBtnClick = this._onBtnClick.bind(this);
  }

  init() {
    this._detectSessionBodyZoom();
    this._bindEvents();
  }

  destroy() {
    this._unbindEvents();

    this.el = null;
    this.emitter = null;

    this.buttons = null;

    this._onBtnClick = null;
  }

  _bindEvents() {
    if (this.buttons) on(this.buttons, "click", this._onBtnClick);
  }
  _unbindEvents() {
    if (this.buttons) off(this.buttons, "click", this._onBtnClick);
  }

  _detectSessionBodyZoom() {
    if(!this.zoom) return;

    this.buttons.forEach((btn) => {
      if (btn.dataset.zoom == this.zoom) btn.setAttribute("aria-selected", true);
    });
  }

  _onBtnClick(event) {
    const { currentTarget } = event;
    const zoom = currentTarget.dataset.zoom;

    this.zoom = zoom;
    currentTarget.setAttribute("aria-selected", true);

    this.buttons.forEach((btn) => {
      if (btn != currentTarget) {
        btn.setAttribute("aria-selected", false);
      }
    });

    // then set zoom
    body.style.zoom = this.zoom;
    this.emitter.emit("SiteScroll.update");
  }

  // get/setter
  set zoom(zoom) {
    localStorage.setItem(LOCAL_STORAGE_KEY, zoom);
    this._zoom = zoom;
  }
  get zoom() {
    return this._zoom;
  }


}

export default ZoomButtons;
